import(/* webpackMode: "eager", webpackExports: ["Button"] */ "D:\\Dev\\oasis_website\\node_modules\\@nextui-org\\button\\dist\\index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Dev\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Dev\\oasis_website\\node_modules\\next-intl\\dist\\esm\\navigation\\shared\\LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Dev\\oasis_website\\node_modules\\next-intl\\dist\\esm\\shared\\NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\swiper\\swiper.css");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\swiper\\modules\\autoplay.css");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\swiper\\modules\\effect-fade.css");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\swiper\\modules\\navigation.css");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\node_modules\\swiper\\modules\\pagination.css");
;
import(/* webpackMode: "eager", webpackExports: ["ButtonBooking"] */ "D:\\Dev\\oasis_website\\src\\components\\home\\btn-book.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocationSliderComponent"] */ "D:\\Dev\\oasis_website\\src\\components\\home\\location-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderComponent"] */ "D:\\Dev\\oasis_website\\src\\components\\home\\product-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderComponent"] */ "D:\\Dev\\oasis_website\\src\\components\\home\\school-slide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwiperCarousel"] */ "D:\\Dev\\oasis_website\\src\\components\\home\\swiper-carousel.tsx");
;
import(/* webpackMode: "eager" */ "D:\\Dev\\oasis_website\\src\\styles\\pages\\home.scss");
